import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

interface BaseTypoProps extends PropsWithChildren {
  className?: string;
  title?: string;
}

enum SizeVariantEnum {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  Base = 'base',
  SM = 'sm',
  XS = 'xs',
}

const classNameBySize: Record<SizeVariantEnum, string> = {
  h1: 'text-primary-500 text-5xl font-extrabold',
  h2: 'text-primary-500 text-3xl font-semibold',
  h3: 'text-primary-500 text-2xl font-semibold',
  h4: 'text-primary-500 text-xl font-semibold',
  base: 'text-base font-normal',
  sm: 'text-sm font-normal',
  xs: 'text-xs font-normal',
};

interface TypoProps extends BaseTypoProps {
  size?: `${SizeVariantEnum}`;
}

const Typo = ({
  className,
  size = SizeVariantEnum.Base,
  children,
  ...rest
}: TypoProps) => (
  <span {...rest} className={cn(classNameBySize[size], className)}>
    {children}
  </span>
);

Typo.H1 = ({ className, children, ...rest }: BaseTypoProps) => (
  <h1 {...rest} className={cn(classNameBySize[SizeVariantEnum.H1], className)}>
    {children}
  </h1>
);
Typo.H2 = ({ className, children, ...rest }: BaseTypoProps) => (
  <h2 {...rest} className={cn(classNameBySize[SizeVariantEnum.H2], className)}>
    {children}
  </h2>
);
Typo.H3 = ({ className, children, ...rest }: BaseTypoProps) => (
  <h3 {...rest} className={cn(classNameBySize[SizeVariantEnum.H3], className)}>
    {children}
  </h3>
);
Typo.H4 = ({ className, children, ...rest }: BaseTypoProps) => (
  <h4 {...rest} className={cn(classNameBySize[SizeVariantEnum.H4], className)}>
    {children}
  </h4>
);

export default Typo;
