'use client';

import { useFormMetadata } from '@conform-to/react';

import { ComponentProps } from 'react';
import { useFormStatus } from 'react-dom';

import ActionButton from '@/app/_components/action-button';

interface ConformSubmitButtonProps
  extends Omit<ComponentProps<typeof ActionButton>, 'type'> {
  formId: string;
}

const ConformSubmitButton = ({
  formId,
  disabled,
  ...rest
}: ConformSubmitButtonProps) => {
  const { pending } = useFormStatus();
  const form = useFormMetadata(formId);

  return (
    <ActionButton
      type="submit"
      disabled={disabled ?? (!form.valid || pending)}
      {...rest}
    />
  );
};

export default ConformSubmitButton;
