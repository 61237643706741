import { useTranslations } from 'next-intl';

import Typo from '@/app/_components/typo';

interface FieldErrorsProps {
  id?: string;
  errors: string[];
  errorParams?: Record<string, number | string>;
}

const FieldErrors = ({ id, errors, errorParams }: FieldErrorsProps) => {
  const t = useTranslations();
  return (
    <div className="flex flex-col text-sm text-destructive">
      {errors.map((error) => (
        <Typo size="xs" key={`${id}-${error}`}>
          {errorParams ? t(error, { ...errorParams }) : t(error)}
        </Typo>
      ))}
    </div>
  );
};

export default FieldErrors;
